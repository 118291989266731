<script setup>
import { RouterView } from 'vue-router'
import Icons from './views/Icons.vue';
import Header from '@/views/common/Header.vue';
import Footer from '@/views/common/Footer.vue';
import Language from '@/views/common/Language.vue';
import { gtag } from './utils/gtm';
import { useGlobalStore } from './stores/global'
import { computed } from 'vue';


let isLogin = computed(() => useGlobalStore().isLogin);

gtag("event", "event", {
  page_location: window.location.href,
  page_path: window.location.pathname,
  page_title: document.title,
});


</script>
<style>
.swal2-container {
  z-index: 10000 !important;
}
</style>
<template>
  <div class="wrap">
    <div class="masthead">
      <Header />
      <Icons />
    </div>
    <div class="container " :class="{ 'container-gray': isLogin }">
      <RouterView />
      <Language />
    </div>

    <Footer />
  </div>
</template>
